<template>
  <div>
    <!-- NAVBAR -->
    <v-row>
      <v-col cols="12" lg="8">
        <div class="d-flex justify-space-between">
          <v-btn icon @click="previous" class="ml-minus-2">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <v-btn icon class="ml-2">
            <v-icon>
              mdi-bookmark-minus-outline
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- TITLE -->
    <div class="mt-5 d-flex">
      <h1 class="font-weight-medium">{{ product.title }}</h1>
      <v-icon
        color="primary"
        class="ml-5"
      >
        mdi-check-circle
      </v-icon>
    </div>
    <v-chip class="mt-2" color="backgroundLight">
      {{ $t('newDeals.productInfo.chip') }}
    </v-chip>
    <v-chip class="mt-2 ml-2" color="backgroundLight">
      {{ $t(`assetSubtype.${product.assetSubType}`) }}
    </v-chip>

    <!-- PRODUCT DETAILS -->
    <Default
      v-if="showDefault"
      :product="product"
      :investorSubscriptionInformation="investorSubscriptionInformation"
      @invest="next"
      :investLoading="loading"
    />
    <component
      v-else
      :is="productId"
      :product="product"
      :investorSubscriptionInformation="investorSubscriptionInformation"
      @invest="next"
      :investLoading="loading"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'NewDealsProduct',
  components: {
    Default: lazyLoad('views/Marketplace/NewDeals/ProductDetailPage/Default'),
    'ae4ce35a-a2c2-42c8-bc26-6ee8643c2683':
      lazyLoad('views/Marketplace/NewDeals/ProductDetailPage/ae4ce35a-a2c2-42c8-bc26-6ee8643c2683'),
    'b5ca686d-fdb3-424f-b218-b594e86d59a4':
      lazyLoad('views/Marketplace/NewDeals/ProductDetailPage/b5ca686d-fdb3-424f-b218-b594e86d59a4'),
    'f4cfa74f-a415-4bac-8411-251076a49948':
      lazyLoad('views/Marketplace/NewDeals/ProductDetailPage/f4cfa74f-a415-4bac-8411-251076a49948'),
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('assets', {
      products: 'all',
    }),
    ...mapGetters('subscription', [
      'investorSubscriptionInformation',
    ]),
    productId() {
      return this.$route.params.product;
    },
    product() {
      return this.products.find((item) => item.id === this.productId);
    },
    showDefault() {
      return this.product.assetStatus === 'Teased'
        || (this.productId !== 'ae4ce35a-a2c2-42c8-bc26-6ee8643c2683'
        && this.productId !== 'b5ca686d-fdb3-424f-b218-b594e86d59a4'
        && this.productId !== 'f4cfa74f-a415-4bac-8411-251076a49948');
    },
  },
  async created() {
    await this.getInvestorSubscriptionInformation(this.productId);
  },
  methods: {
    ...mapActions('subscription', [
      'getInvestorSubscriptionInformation',
      'initSubscriptionOrder',
      'reset',
    ]),
    previous() {
      this.reset();
      this.$router.go(-1);
    },
    async next() {
      this.loading = true;
      try {
        await this.initSubscriptionOrder(this.productId);
        this.$router.push({
          name: 'Subscription',
          params: { product: this.productId },
        });
      } catch (error) {
        this.$notify.error(error.message);
      }
      this.loading = false;
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.align-right {
  text-align: right;
}
.field-desc {
  font-size: 12px;
}
.v-divider {
  margin: .5em 0 1em 0;
}
</style>
